<template>

  <div class="w">

    <!-- 主体内容 -->
    <div class="mcontent index">

      <div id="main">
        <!-- 主体中间内容 -->
        <div id="min">
          <!--    菜单栏-->
          <navMenu v-if="!route.path.includes('bifenLiveScores') && !route.path.includes('frontLiveScores')" ></navMenu>
          <div id="Layer1" style="position: absolute; height: 1px; z-index: 5">
            <div id="showoptional2"></div>
          </div>

          <div id="mintable">
            <span id="notify"></span>
            <span id="live">

                      <div style="margin: 0 0 -2px 0;" v-if="!route.path.includes('frontLiveScores')">
                       <el-menu
                         :default-active="activeDefault"
                         active-text-color="#fff"
                         mode="horizontal"
                         text-color="#212121"
                         @select="handleZiSelect"
                       >
                        <el-menu-item index="/all" class="menuZiItem">Tất cả</el-menu-item>
                        <el-menu-item index="/hot" class="menuZiItem">Trận Hot</el-menu-item>

                           <div style="float: left; padding: 8px 0 0 8px;cursor:pointer" class="aClick"
                                @click="showNumHandle">
                                  Ẩn <span id="hiddencount">{{ showNum }}</span>&nbsp;[Hiển thị hết]
                          </div>

                      </el-menu>

                     </div>

                    <!--表格题头-->
                    <div class="headerUp">
                       <table style="border-collapse: collapse;table-layout: fixed;width: 100%;">
                          <tr>
                              <td
                                style="width: 4%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">{{
                                  '    '
                                }}</td>

                              <td
                                style="width: 10%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">Giờ</td>
                              <td
                                style="width: 23%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">Chủ</td>
                              <td style="width: 10%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">Tỷ số</td>
                              <td style="width: 23%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">Khách</td>
                              <td style="width: 5px;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">
                                <i class="icon iconfont icon-qizi-copy"></i>
                              </td>
                              <td
                                style="width: 5%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">H-T</td>
                              <td style="width: 6%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">Số liệu</td>
                              <td style="width: 15%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">select</td>
                          </tr>
                      </table>
                    </div>

              <!--  主要表格  -->
                    <div id="table_live" width="100%" v-for="(item, index) of aData" :key="index">
                      <!--  赛事信息  -->
                      <table :id="'head' + index" :class="getClassName(item, index)" style="border-bottom: 1px solid #C1C1C1">
                        <td style="padding: 10px 0 0 10px;width: 4%;border-bottom-style: none;">
                            <div style="width: 22.5px;height: 15px;">
                              <el-image :src="getCnoImg(bData[item[1]])" alt="国旗"/>
                            </div>
                        </td>
                        <td style="float: left;border-bottom-style: none;">
                            <h5>
                              {{ bData[item[1]][8] }}
                            </h5>
                        </td>

                        <td style="float: right;padding: 0 10px 0 0;">
                          <span onclick="" name="collapse98" class="expand"></span>
                        </td>
                      </table>

                      <!--  队伍信息  -->
                      <table style="table-layout: fixed;width: 100%;border-bottom: 1px solid #C1C1C1">
                           <tr>
                              <td
                                style="width: 5%;border-bottom-style:none;text-align:center;">
                                  <p>{{ handleStartDate(item[6]) }} </p>

                                </td>

                              <td style="width: 5%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">
                                <span class="status"  v-show="item[37] !== undefined" v-html="item[37]"></span>
                                  <span class="status" v-show="item[38] !== undefined" v-html="item[38]"></span>
                                  <span class="status" v-show="item[8] === -14">{{ 'Hoãn' }}</span>
                                  <span class="status" v-show="item[8] === -1">{{ 'FT' }}</span>
                                  <span class="status" v-show="item[8] === -10">{{ 'Hủy' }}</span>
                                  <span class="status1" v-show="item[8] === -11">{{ 'Treo' }}</span>
                                  <span v-show="(item[8] === 1 || item[8] === 2 || item[8] === 3 || item[8] === 4) && item[37] !== undefined && item[37] !== 'HT'">
                                    <img src='/images/in.gif' border=0>
                                  </span>
                              </td>
                             <td style="width: 5%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">
                              </td>
                              <td style="width: 23%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">
                                 <div style="text-align: right">
                                       <div style="color: #888;transform: scale(.85);display: inline-block;">
                                         {{ item[17] === '' ? '' : '[' + item[17] + ']' }}
                                       </div>
                                       <div
                                         style="background-color: #fdd668;color: #9c6c44;transform: scale(.85);display: inline-block;font-size: 14px;">
                                         {{ item[15] === 0 ? '' : item[15] + '' }}
                                         </div>

                                       <div class="aClick" style="display: inline-block;cursor: pointer;"
                                            v-html="item[4]" @click="clickSYZ(item)">
                                         </div>

                                     </div>
                              </td>

                              <td style="width: 10%;border-bottom-style:none;text-align:center;padding: 5px 0 0 0;">
                                      <div @mouseover="showDetail(item, $event, $event.target)" @mouseout="MM_showHideLayers('winScore','','hide')"
                                           style="cursor: pointer; text-align: center">
                                        <!--  item[8] 为比赛状态， 1=进行中; 2=还不知道; 3=上半场结束或下半场开始 -->
                                       <b style="color: #00f; cursor: pointer;" v-show="item[8] !== -1">
                                         {{
                                           item[37] !== undefined ? item[9] + ' - ' + item[10] : ' - '
                                         }}
                                          </b>
                                         <b style="color: #d64444;" v-show="item[37] === undefined && item[8] === -1">
                                           {{
                                             item[9] + ' - ' + item[10]
                                           }}
                                          </b>
                                     </div>
                              </td>

                              <td style="width: 23%;border-bottom-style:none;text-align:left;padding: 5px 0 0 0;">
                                 <div class="aClick" style="display: inline-block;cursor: pointer;"
                                      v-html="item[5]" @click="clickSYZ(item)">
                                         </div>
                                      <p
                                        style="background-color: #fdd668;color: #9c6c44;transform: scale(.85);display: inline-block;font-size: 14px;">
                                       {{ item[16] === 0 ? '' : item[16] }}
                                       </p>
                                      <p style="color: #888;transform: scale(.85);display: inline-block;">
                                        {{ item[18] === '' ? '' : '[' + item[18] + ']' }}
                                     </p>
                              </td>

                              <td style="width: 5%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">
                                 <p>
                                       {{
                                     item[27] === 0 && item[28] === 0 && (item[8] !== 1 && item[8] !== 2 && item[8] !== 3 && item[8] !== 4)
                                         ? ' - ' : item[27] + ' - ' + item[28]
                                   }}</p>
                              </td>

                              <td
                                style="width: 5%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">
                                 <p>{{ item[11] }} - {{ item[12] }} </p>
                              </td>

                              <td style="width: 6%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">
                                    <div>
                                       <i class="icon iconfont icon-qizhi-"  style="cursor: pointer;" @click="clickSYZ(item)"></i>
<!--                                       <i class="icon iconfont icon-matchdata"></i>-->
                                    </div>
                              </td>

                              <td style="width: 15%;border-bottom-style: none;text-align:center;padding: 5px 0 0 0;">
                                    <div>
                                       <span class="odds1"> {{ addsDataHandle(item[0]) }} </span>
                                      <!--                                       <span class="odds1"> {{ handleOdds(item[37], 2) }} </span>-->
                                      <!--                                       <span class="odds1"> {{ handleOdds(item[37], 4) }} </span>-->
                                    </div>
                                     <div>
<!--                                       <span class="odds2"> {{ handleOdds(item[37], 11) }} </span>-->
                                       <!--                                       <span class="odds2"> {{ handleOdds(item[37], 10) }} </span>-->
                                       <!--                                       <span class="odds2"> {{ handleOdds(item[37], 12) }} </span>-->
                                     </div>
                              </td>
                          </tr>

                      </table>
                    </div>


            </span>
          </div>

        </div>

      </div>

    </div>



    <!-- 比分弹窗 -->
    <div id="winScore" class="scoretab" style="position: absolute; z-index: 8; top: 422px; left: 355.531px; visibility: hidden;" @mousemove="MM_showHideLayers('winScore','','show')" @mouseout="MM_showHideLayers('winScore','','hide')">
      <table width="400" cellpadding="0" cellspacing="1" class="odds-table-bg">
        <tbody>
        <tr>
          <td height="20" colspan="5" class="hand-bg" align="center">
            <font color="white"><b>Dữ liệu trận đấu</b></font>
          </td>
        </tr>


        <tr class="jqSubTitle" align="center">
          <td height="20" width="44%">
            <font><b v-html="scoreDetailData.MYteam"></b></font>
          </td>
          <td width="12%"><b>Phút </b></td>
          <td width="44%">
            <font><b v-html="scoreDetailData.YOUteam"></b></font>
          </td>
        </tr>

        <tr class="nodata" id="noeven" v-show="isNodata">
          <td colspan="5">Không có dữ liệu!</td>
        </tr>

        <tr align="center" v-for="(item, index) of scoreDetailData['temData']" :key="index">
          <td align="right" height="18" class="white-bg" style="padding:0 2px">
            <span class="detail_player" v-show="item[1] === '1'" >
              {{ item[4].split('4png')[0] }}
            </span>
            <img style="margin:3px 2px;" src="/images/bf_img/4.png" v-show="item[4].includes('4png') && item[1] === '1'">
            <span class="detail_player" v-show="item[1] === '1'">
              {{  item[4].split('4png')[1] }}
            </span>
            <img style="margin:3px 2px;" src="/images/bf_img/5.png" v-show="item[4].includes('4png') && item[1] === '1'">

            <img style="margin:3px 2px;" src="/images/bf_img/1.png" v-show="item[1] === '1' && item[2] === '1'">
            <img style="margin:3px 2px;" src="/images/bf_img/2.png" v-show="item[1] === '1' && item[2] === '2'">
            <img style="margin:3px 2px;" src="/images/bf_img/7.png" v-show="item[1] === '1' && item[2] === '7'">
            <img style="margin:3px 2px;" src="/images/bf_img/9.png" v-show="item[1] === '1' && item[2] === '9'">
            <img style="margin:3px 2px;" src="/images/bf_img/11.png" v-show="item[1] === '1' && item[2] === '11'">
            <img style="margin:3px 2px;" src="/images/bf_img/13.png" v-show="item[1] === '1' && item[2] === '13'">
          </td>

          <td width="12%" class="gray-bg2">{{ item[3] }}'</td>

          <td align="left"   class="white-bg">
            <img style="margin:3px 2px;" src="/images/bf_img/1.png" v-show="item[1] === '0' && item[2] === '1'">
            <img style="margin:3px 2px;" src="/images/bf_img/2.png" v-show="item[1] === '0' && item[2] === '2'">
            <img style="margin:3px 2px;" src="/images/bf_img/7.png" v-show="item[1] === '0' && item[2] === '7'">
            <img style="margin:3px 2px;" src="/images/bf_img/9.png" v-show="item[1] === '0' && item[2] === '9'">
            <img style="margin:3px 2px;" src="/images/bf_img/11.png" v-show="item[1] === '0' && item[2] === '11'">
            <img style="margin:3px 2px;" src="/images/bf_img/13.png" v-show="item[1] === '0' && item[2] === '13'">
            <span class="detail_player" v-show="item[1] === '0'" >
              {{ item[4].split('4png')[0] }}
            </span>
            <img style="margin:3px 2px;" src="/images/bf_img/4.png" v-show="item[4].includes('4png') && item[1] === '0'">
            <span class="detail_player" v-show="item[1] === '0'">
              {{  item[4].split('4png')[1] }}
            </span>
            <img style="margin:3px 2px;" src="/images/bf_img/5.png" v-show="item[4].includes('4png') && item[1] === '0'">

          </td>
        </tr>


        </tbody>
      </table>
      <table width="100%" cellpadding="0" cellspacing="1" class="odds-table-bg">
        <tbody>
        <tr>
          <td height="20" colspan="3" class="hand-bg" align="center">
              <b style="color:white">Thống kê trận đấu</b>
          </td>
        </tr>
        <!-- <tr class="nodata" id="nostats">
          <td colspan="5">--------------------</td>
          <td colspan="5">--------------------</td>
          <td colspan="5">--------------------</td>
        </tr> -->
        <tr height="18" class="white-bg" align="center">
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'] !== undefined ? scoreDetailData['matchStat']['oneNum'] : ''}}</td>
          <td class="gray-bg2"> Cú sút</td>
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'    ] !== undefined ? scoreDetailData['matchStat']['oneNum2'] : ''}}</td>
        </tr>

        <tr height="18" class="white-bg" align="center" v-show="scoreDetailData['matchStat'] !== undefined && scoreDetailData['matchStat']['twoNum'] !== undefined">
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'] !== undefined ? scoreDetailData['matchStat']['twoNum'] : ''}}</td>
          <td class="gray-bg2"> Sút trúng</td>
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'] !== undefined ? scoreDetailData['matchStat']['twoNum2'] : ''}}</td>
        </tr>

        <tr height="18" class="white-bg" align="center" v-show="scoreDetailData['matchStat'] !== undefined">
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'] !== undefined ? scoreDetailData['matchStat']['rightSta'] : ''}}</td>
          <td class="gray-bg2">TL kiểm soát bóng</td>
          <td width="25%" class="gray-bg2">{{ scoreDetailData['matchStat'] !== undefined ? scoreDetailData['matchStat']['leftSta'] : ''}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>


</template>

<script setup>
import {computed, onBeforeMount, onUpdated, ref, watchEffect} from 'vue';
import {commonGetReqest, getLiveList} from "@/api/bifenApi";
import store from "@/store";
import {useRoute, useRouter} from "vue-router";
import {openFullScreen2} from "@/utils/ruoyi"
import {getUTCTime} from "@/utils/bifenUtil";
import navMenu from "@/components/navMenu.vue";

const route = useRoute();
const router = useRouter();


// 组件挂载之前调用
onBeforeMount(() => {
  getList();
  handleOdds();
  openFullScreen2()
  vnJs()
});

onUpdated(() => {

});

const isNodata = computed(() =>{
  try {
    return scoreDetailData.value['temData'].length === 0
  } catch (e) {
    return false
  }
})


const activeDefault = ref('/all') // 默认激活的菜单
const goAddIndex = ref(false)
const addsData = ref([])
const aData = ref([])
const aDataCopy = ref([])
const bData = ref([])
const cData = ref([])
const showNum = ref(0)
const awaitShowNumFlag = ref(false)
const scoreDetailData = ref({})
const rq = ref([])
const tc = ref([])

function vnJs() {
  try {
    getDetailVnJs()
    // 与平台保持一致，10秒执行一次请求
    setInterval(() => {
      getDetailVnJs()
    }, 10000)
  } catch (e) {
    console.log("更新比分弹框数据出错,出错信息为--->" + e)
  }
}

// 判断是否显示题头
function getClassName(item, index) {

  if (index !== 0 && aData.value[index - 1] !== undefined && aData.value[index - 1][1] === item[1]) {
    return "unShowRr"
  }

  return "showRr"
}

function raceClass(item, index) {

  if (index !== 0 && aData.value[index - 1] !== undefined && aData.value[index - 1][1] === item[1]) {
    return "unShowRace"
  }

  return "showRace"
}

// 处理赔率数据， 存在请求超时问题，暂时不使用
async function handleOdds(row, index) {

}

function addsDataHandle(index) {
  for (let i = 0; i < addsData.value.length; i++) {
    const v = addsData.value[0]
    if (v.includes(index)) {
      const v1 = v.split(',')[0].replace(index,'').replace('!', '')
      return v1
    }
  }
}


// 点击所有者处理函数
function clickSYZ(row) {
  if (route.path.includes('bifenLiveScores')) {
      // const pathStr = "/bifen/detail?id=" + row[0]
      // router.push(pathStr);
    window.open("https://369bongda.com/list-35.html?sid=" + row[0])
  } else {
    window.open(window.location.origin +"/liveScores/detail?id=" + row[0])
  }

}

// 初始化菜单
function back() {
  store.state.topActiveMenu = 'ball1'
}



// 监听器
watchEffect(() => {
  const path = route.path
  back(path)
  const live = store.state.liveScores
  try {
    const data = JSON.parse(live).data
    if (data) {
      handleData(data)
    }
  } catch (e) {
  }
})

// 获取实时比分
const getList = async () => {
  console.log("调用接口")
  try {
    let res = await getLiveList();
    const data = res.data
    handleData(data);
  } catch (e) {
    console.log("请求getLiveList 报错：", e.data)
  }

};

// 处理比赛数据
function handleMatchData() {
  const A = aData.value.filter(val => val[8] === 1 || val[8] === 2 || val[8] === 3 || val[8] === 4)
  // 获取开始时间与结束时间，来判断是否正在比赛
  for (var i, r, t, u = getUTCTime(), n = 0; n < A.length; n++) {
    const date = aData.value[n][7].substring(0, aData.value[n][7].length - 2) + '00'
    i = new Date(date);
    if (A[n][35] !== null && A[n][35] !== "") {
      r = A[n][35] !== "" ? "<br>AT+<font style='color: #f00'>" + A[n][35] + "<\/font>" : "";
    } else {
      r = ''
    }
    let goTime = 0;
    if (A[n][8] === 1) {
      goTime = Math.floor((u - i) / 6e4)
      if (goTime > 45) {
        let t = goTime - 45
        goTime = "45+" + (t > 15 ? 15 : t)
      }
      if (goTime < 1) {
        goTime = "1"
      }
    } else if (A[n][8] === 3) {
      goTime = Math.floor((u - i) / 6e4) + 46
      if (goTime > 90) {
        t = goTime - 90
        goTime = "90+" + (t > 15 ? 15 : t)
      }
      if (goTime < 46) {
        goTime = "46"
      }
    }
    // 闪烁图标

    if (goTime === 0) {
      aData.value[n].push('HT')
    } else {
      aData.value[n].push(goTime)
    }
    if (r !== '') {
      aData.value[n].push(r)
    }
  }
}

// 处理table数据
function handleData(data) {
  if (data === undefined || data.A.length === 0 || data.B[0] === undefined) {
    return
  }
  aData.value = []
  aDataCopy.value = []
  bData.value = []
  //cData.value = []
  aDataCopy.value = JSON.parse(data.A[0].data).filter(val => val !== null)
  if (activeDefault.value === '/all') {
    aData.value = JSON.parse(data.A[0].data).filter(val => val !== null)

    if (route.path.includes('frontLiveScores')) {
      aData.value = aData.value.splice(0, 20)
    }
    // console.log(aData.value)
    bData.value = JSON.parse(data.B[0].data)
    //cData.value = JSON.parse(data.C[0].data)
  } else {
    bData.value = JSON.parse(data.B[0].data)
    aData.value = JSON.parse(data.A[0].data).filter(val => val !== null && bData.value[val[1]][4] === 1)
    //cData.value = JSON.parse(data.C[0].data)
  }
  handleMatchData();
  handleShowNum(aDataCopy, bData)
}

const handleBData = (index) => {
  try {
    return bData.value[index]
  } catch (e) {
    console.log(bData.value[i])
    console.log(i, index)
    console.log("报错了------")
    return ''
  }

}

// 处理国家图片
const getCnoImg = (row) => {
  try {
    return "https://img_cms.thscore.fun/mini/fbcountry/" + row[10] + "-flag-small.png"
  } catch (e) {
    console.log("报错了------")
    return ''
  }

}


const addColor = (row, column, rowIndex, columnIndex) => {
  return "color:#090707";
}

const getHz = (row) => {
  try {
    if (row === undefined) {
      return
    }
    return row
  } catch (e) {
    console.log("报错了------")
    return ''
  }

}

function tableRowStyle({row}) {
  return {'background-color': '#e5e5e5', 'color': '#090707'}
}

function tableRowStyleRice({row}) {
  return {
    'height': '10px'
  }
}


// 处理比赛开始时间
function handleStartDate(dateStr) {
  const time = new Date(dateStr);
  time.setHours(time.getHours() + 8);
  const formattedHours = ('0' + time.getHours()).slice(-2);
  const formattedMinutes = ('0' + time.getMinutes()).slice(-2);
  return formattedHours + ':' + formattedMinutes;
}


// 详情
function MM_showHideLayers() { //v6.0
                               //    if (window.ActiveXObject) {
  const winScore = document.querySelector("#winScore");
  winScore.style.visibility= "hidden"

  // var i, p, v, obj, args = MM_showHideLayers.arguments;
  // for (i = 0; i < (args.length - 2); i += 3) if ((obj = MM_findObj(args[i])) != null) {
  //   v = args[i + 2];
  //   if (obj.style) {
  //     obj = obj.style;
  //     v = (v == 'show') ? 'visible' : (v == 'hide') ? 'hidden' : v;
  //   }
  //   obj.visibility = v;
  // }
}
//鼠标滑过队伍 弹框事件
function showDetail(row, event, e) {
  if(row[8] !== 1 && row[8] !== 2 && row[8] !== 3 && row[8] !== -1) return;
  const winScore = document.querySelector("#winScore");
  winScore.style.visibility= "visible";
  winScore.style.left= event.pageX - 210 + 'px';
  winScore.style.top = event.pageY + 20 + 'px';
  scoreDetailData.value['MYteam'] = row[4];
  scoreDetailData.value['YOUteam'] = row[5];

  scoreDetailData.value['matchStat'] = {}
  // console.log(event,scoreDetailData)

  const id = row[0] + ''
  console.log("id:" + id);
  // rq 的数据从 index.html 直接引入的js 文件
  const  jdData = rq.value
  scoreDetailData.value['temData'] = []
  for (let i = 0; i < jdData.length; i++) {
    let data = jdData[i]
    if (data.includes(id)) {
      // jinqiuData.value.push(data.split('^'))
      if (data.includes("↑")) {
        data = data.replace("↑", "4png" )
      }
      if (data.includes("↓")) {
        data = data.replace("↓", "" )
      }
      scoreDetailData.value['temData'].push(data.split('^'))
    }
  }
  console.log(scoreDetailData.value['temData'])

  const  tcData = tc.value
  for (let i = 0; i < tcData.length; i++) {
    const data = tcData[i]
    if (data.includes(id)) {
      // jinqiuData.value.push(data.split('^'))
      try {
        const data1 = data.substring(data.indexOf("%") - 2)
        const v = data1.split(';')
        let v2 = v[0].split(',')
        scoreDetailData.value['matchStat'].rightSta = v2[0]
        scoreDetailData.value['matchStat'].leftSta = v2[1]

        const data2 = data.split(';')
        scoreDetailData.value['matchStat'].oneNum = data2[3].split(',')[1]
        scoreDetailData.value['matchStat'].oneNum2 = data2[3].split(',')[2]
        if (data2[4].split(',')[1] !== data2[3].split(',')[1] &&
            data2[4].split(',')[2] !== data2[3].split(',')[2]) {
          scoreDetailData.value['matchStat'].twoNum = data2[4].split(',')[1]
          scoreDetailData.value['matchStat'].twoNum2 = data2[4].split(',')[2]
        }
      } catch (e) {
        console.log("鼠标移动弹框处理数据出错")
      }
    }
  }


}

function showOddsChange(id, event) {

}

function hideOddsDetail() {

}

// handleScroll(event) {
//     console.log(event);
//     console.log("键入处理滚轮方法");
//     const deltaY = event.deltaY
//     const y = event.y
//     console.log(y);
//     let oldTop = this.pageTop

//     if (y === 210) {
//         top = 210
//     }

//     this.pageTop = top
//     const AdLayer1 = document.getElementById('AdLayer1');
//     AdLayer1.style.top = top + 'px'
//     // 处理鼠标滚动事件的方法
//     // event.deltaY 可以获取鼠标滚动的方向和速度
//     // 可以根据需求进行相应的操作
// },

function handleZiSelect(key, keyPath) {
  if ('/all' === key) {
    showNumHandle()
  } else {
    awaitShowNumFlag.value = true
  }
  activeDefault.value = key
  getList();
  openFullScreen2();
}


// 处理热数据
function showNumHandle() {
  showNum.value = 0
  activeDefault.value = '/all'
  getList()
  openFullScreen2();
}


// 获取隐藏数量
async function handleShowNum(aData, bData) {
  await awaitShowNum(aData, bData)
}

// 异步执行不热数据的隐藏
// bData 数据中，数组为 4 的数据为0，则表示是需要隐藏的赛事，不是火热的赛事
function awaitShowNum(aData, bData) {
  if (awaitShowNumFlag.value === false) {
    return
  }
  awaitShowNumFlag.value = false
  showNum.value = 0
  for (let key in aData.value) {
    const v = aData.value[key]
    const bIndex = v[1]
    try {
      if (bData.value[bIndex][4] === 0) {
        showNum.value++
      }
    } catch (e) {
    }
  }
}

async function getDetailVnJs () {
  const url = '/gf/data/detail_vn.js'
  try {
    const res = await commonGetReqest({url});
    let msg = res.msg
    const rqHandle = msg.substring(msg.indexOf('rq['), msg.indexOf('tc['))
    const tcHandle = msg.substring(msg.indexOf('tc[')).replace('\r\n', '')
    const datas = rqHandle.split(';')
    rq.value = []

    for (let i = 0; i < datas.length; i++) {
      const v = datas[i]
      const spV = v.substring(v.indexOf('=') + 1)
      const evValue = eval(spV)
      if (evValue !== undefined) {
        rq.value.push(evValue)
      }
    }

    tc.value = []
    const tcDatas = tcHandle.split('\";')
    for (let i = 0; i < tcDatas.length - 1; i++) {
      const v = tcDatas[i]
      const spV = "\"" + v.substring(v.indexOf('=') + 1).replace('\"', '') + "\""
      const evValue = eval(spV)
      if (evValue !== undefined) {
        tc.value.push(evValue)
      }
    }
  } catch (e) {
    console.log("请求detail_vn.js 文件报错，报错信息为：" + e.data)
    //
    //
  }


}

// function setMatchTime() {
//   r = A[n][35] != undefined && A[n][35] != "" ? "<br>AT+<font class='red'>" + A[n][35] + "<\/font>" : "";
//   A[n][8] == "1" && (goTime = Math.floor((u - i) / 6e4),
//   goTime > 45 && (t = goTime - 45,
//       goTime = "45+" + (t > 15 ? 15 : t)),
//   goTime < 1 && (goTime = "1"),
//       document.getElementById("time_" + A[n][0]).innerHTML = "<span id='got_" + A[n][0] + "'>" + goTime + "<img src='/images/in.gif' border=0><\/span>" + r);
//   A[n][8] == "3" && (goTime = Math.floor((u - i) / 6e4) + 46,
//   goTime > 90 && (t = goTime - 90,
//       goTime = "90+" + (t > 15 ? 15 : t)),
//   goTime < 46 && (goTime = "46"),
// }

</script>


<style lang="less" scoped>
.crumbs {
  width: 1060px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
  margin: 5px auto;
  display: flex;
  align-items: center;

  h1 {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.mcontent {
  width: 1060px;
  margin: 0 auto 10px auto;
  min-height: 700px;

}

#ad_middle_122 {
  text-align: center;
  width: 1060px;
  margin: 0 auto;
  overflow: hidden;

  a img {
    padding-top: 5px;
  }
}


#main {
  width: 1060px;
  clear: both;
  margin: 0 auto;
  padding: 0 0 6px 0;
  #left {
    // overflow: hidden;
    padding: 0;
    min-height: 1320px;
    width: 155px;
    float: left;
    margin-right: 10px;

    .lrdiv {
      padding: 0 10px 1px 10px;
      overflow: hidden;
      border-radius: 4px;
      width: 135px;
      background-color: #fff !important;

      .leftnav {
        padding: 5px 0 0 0;
      }

      .lr_tit,
      .lr_titA {
        font-weight: bold;
        display: block;
        line-height: 35px;
        font-size: 14px;
        padding-left: 5px;
      }

      .slideBtn {
        position: relative;
        cursor: pointer;
      }
    }

    #div_survey {
      width: 100px;
      height: 100px;
      background-color: #000;
      // top: 10px; // 距离顶部2211px，则不往上滑动， 也就是滑动时与顶部的距离
      // position: sticky; // 粘性定位
      // margin-top: 10px; // 滑到顶部的距离

      right: 0;
      bottom: 0;
      position: sticky;
    }


  }

  #min {
    background-color: #fff !important;
    float: left;
    width: 1040px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom:10px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);
    #tools.tools {
      overflow: initial;
      line-height: 28px;
      height: 28px;
      overflow: hidden;
      border-color: @secondColor;
      border-bottom: 3px solid @primaryColor;

      .switch-btn {
        background: @primaryColor;
        height: 22px;
        padding: 1px;
        margin: 2px 10px;
        border-radius: 20px;
        display: inline-block;
        position: relative;

        span {
          color: @textwhite;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          padding: 0 3px;
          border-radius: 20px;
          display: inline-block;
          cursor: pointer;

          .on {
            background: @whitebg;
            color: @primaryColor;
            font-weight: bold;
          }
        }

        .pop {
          min-width: 150px;
          background: @gray80;
          color: @textwhite;
          text-align: center;
          line-height: normal;
          padding: 15px;
          border-radius: 5px;
          position: absolute;
          top: 26px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 9;
          box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
        }
      }

      .filterLi2 a {
        padding: 0 7px 0 10px;
      }

      li {
        float: left;
        margin-right: 2px;
        cursor: pointer;
        height: 30px;
        vertical-align: bottom;

        a {
          background-color: @primaryColor;
          transition: all .2s linear;
          line-height: 28px;
          text-align: center;
          display: block;
          float: left;
          padding-right: 5px;
          padding-left: 5px;
          min-width: 30px;
          text-decoration: none;
          border-radius: 3px 3px 0 0;
        }
      }


    }

    .setit {
      float: right;
      background: #e5e5e5 url("../../../public/images/setup.png") no-repeat 8px;
      padding: 0 8px 0 30px;
      border-radius: 3px 3px 0 0;
      cursor: pointer;
    }
  }

  #right {
    width: 155px;
    float: left;
    margin-left: 10px;

    .n-search {
      background-color: #fff !important;
      padding: 6px 10px;
      overflow: hidden;
      border-radius: 4px;
      margin-bottom: 5px;

      .input_text {
        border: 0;
        font-size: 12px;
        width: 105px;
        background: url("../../../public/images/f.png") no-repeat 5px;
        border-radius: 2px;
        padding: 5px 0 5px 30px;
      }
    }

    .adclass {
      margin-bottom: 5px;
      text-align: center;
    }
  }

  .adclass {
    text-align: center;
    margin-left: -10px;
    margin-bottom: 5px;

    img {
      width: 155px;
      margin-bottom: 3px;
      border-width: 0;
    }
  }

  #div_ad_float {
    padding-left: 24px;
    padding-top: 10px;
  }
}

#myGamesCount,
#hiddencount,
#nshowhide {
  color: #f60;
}

#myGamesCount,
#hiddencount,
#nshowhide {
  font-weight: bold;
}


.flyClose {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(0 0 0/40%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
}


.scoretitle {

  .allno {
    display: inline-block;
    width: 11px;
    height: 12px;
    background-image: url("../../../public/images/allno.png");
    margin-top: 2px;
    cursor: pointer;
  }

  td {
    color: rgba(255, 255, 255, .7);
    padding-bottom: 3px;
    line-height: 18px;
    background-color: @secondColor;
  }
}

#mintable {
  td {
    border-bottom: 1px solid transparent;
    border-color: #e5e5e5 !important;
    text-align: center;
  }

  .tds {
    height: 36px;
  }

  .yellowcard {
    background-color: #fdd668;
    color: #9c6c44;
  }

  .team-hg {
    color: #888;
    transform: scale(.85);
    display: inline-block;
  }

  .icon-matchdata {
    color: #4fa82a;
  }

  .analyze-icon {
    color: #888;
    line-height: 14px;
    text-align: center;
  }

}

.w {
  font-size: 12px;
  //width: 1040px;
  font-family: "Tahoma", "Helvetica Neue", Helvetica, Arial, sans-serif;
}


#table_live tr:first-child ~ tr {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;

}

.el-table {
  font-size: 12px;
  color: #222;
}

.scoretab {
  background: #fff;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 0 10px #666;
}

.headerUp {
  background-color: @primaryColor;
  height: 22px;
  width: 100%;
  color: #fff;
}


.aClick:hover {
  color: #f60;
  text-decoration: underline;
  cursor: pointer;
}

.odds1 {
  color: #800;
  height: 17px;
  padding: 0 10px 0 0;

}

.odds2 {
  color: #00b;
  height: 17px;
  padding: 0 10px 4px 5px;
}


.unShowRr {
  display: none;
}

.showRr {
  height: 29px;
  background-color: #f0f0f0;
  text-align: center;
  width: 100%;
  padding: 0 0 4px 0;
}

.showRace {
}

.unShowRace {

}

.menuZiItem {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  line-height: 35px;
  height: 28px;
  font-weight: bold;
  color: #222222;
  background-color: #E5E5E5;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-bottom: none;
  float: left;
  width: 10%;
  font-size: 12px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0;
  color: @primaryColor;
  background-color: @primaryColor;
}

.el-menu--horizontal > .el-menu-item {
  margin: 1px 0 -3px 0;
  border-radius: 4px 4px 0 0;
}

.scoretab {
  background: #fff;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 0 10px #666;
  td {
    line-height: 22px;
    font-size: 12px;
  }
  .hand-bg {
    background-color: #2760AB;
  }
}

.closebtn {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(0,0,0,.3);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.odds-table-bg {
  background-color: #d0d0d0;
  .gray-bg2 {
    background-color: #f9f9f9;
  }
  .nodata {
    background-color: #fff;
    height: 30px;
    text-align: center;
  }
}
.jqSubTitle td {
  background: #f3fdf1;
  font-weight: bold;

}

.white-bg{
    background: #FFFFFF !important;;
  }

.detail_player {
  display: inline-block;
  max-width: 141px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status {
  color: #06f;
}

.status1 {
  color: green;
}

</style>


