import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../views/Layout.vue'
import LiveScores from '../views/Home/LiveScores.vue'
import CustomLayour from '../views/CustomLayour.vue'
const routes = [
    {
        path: '/',
        component: Layout,
        meta: {title: "Bóng đá", activeMenu: "",keepAlive: true},
        redirect: '/consult',
        children: [
            {
                path: '/liveScores',
                meta: {title: "Tỷ số bóng đá", activeMenu: "",keepAlive: true},
                component: LiveScores,

            },
            {
                path: '/liveScores/result',
                meta: {title: "Kết quả bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Result.vue')
            },
            {
                path: '/liveScores/agenda',
                meta: {title: "Lịch thi đấu bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Agenda.vue')
            },
            {
                path: '/liveScores/detail',
                meta: {title: "detail", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Detail.vue')
            },
            {
                path: '/liveScores/detail/:id(\\d+)',
                meta: {title: "detail", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Detail.vue')
            },
            {
                path: '/liveScores/analyze',
                meta: {title: "analyze", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Analyze.vue')
            },
            {
                path: '/liveScores/analyze/:id(\\d+)',
                meta: {title: "analyze", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Analyze.vue')
            },

        ]
    },
    //篮球
    {
        path: '/basket',
        component: CustomLayour,
        meta: {title: "bong_ro", activeMenu: "",keepAlive: true},
        redirect: '/basket',
        children: [
            {
                path: '/basket',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/basket.vue')
            },
            {
                path: '/basket/boogAgenda',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/boogAgenda.vue')
            },
            {
                path: '/basket/boogResult',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/boogResult.vue')
            },
            {
                path: '/basket/analysis/',
                meta: {title: "", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/analysis.vue')
            },
            {
                path: '/basket/skillStatistics',
                meta: {title: "", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/skillStatistics.vue')
            },
        ]
    },
    //直播
    {
        path: '/LiveBroadcast',
        component: CustomLayour,
        meta: {title: "Trực tiếp", activeMenu: "",keepAlive: true},
        redirect: '/LiveBroadcast',
        children: [
            {
                path: '/LiveBroadcast',
                meta: {title: "Sống video", activeMenu: "",keepAlive: true},
                component: () => import('../views/LiveBroadcast/LiveBroadcast.vue')
            },
            {
                path: '/LiveBroadcast/video',
                meta: {title: "video", activeMenu: "",keepAlive: true},
                component: () => import('../views/LiveBroadcast/video.vue')
            },
        ]
    },
    //资讯
    {
        path: '/consult',
        component: CustomLayour,
        meta: {title: "Thông tin", activeMenu: "",keepAlive: true},
        redirect: '/consult',
        children: [
            {
                path: '/consult',
                meta: {title: "tin mới", activeMenu: "",keepAlive: true},
                component: () => import('../views/message/consult.vue')
            },
            {
                path: '/consult/details/:id',
                meta: {title: "Bài viết", activeMenu: "",keepAlive: true},
                component: () => import('../views/message/details.vue')
            },
            {
                path: '/consult/football/:tag/:pageName',
                meta: {title: "", activeMenu: "",keepAlive: true},
                component: () => import('../views/message/football.vue')
            },
        ]
    },
    {
        path: '/login',
        meta: {title: "login", activeMenu: ""},
        component: () => import('../views/Login.vue')
    },
    {
        path: '/study',
        meta: {title: "study", activeMenu: ""},
        component: () => import('../views/Home/Study.vue')
    },


    // 单页面，提供给 SEO 同事
    {
        path: '/bifen/Layout',
        component: () => import('../views/seoPage/Layout.vue'),
        meta: {title: "Bóng đá", activeMenu: "",keepAlive: true},
        redirect: '/bifen/liveScores',
        children: [
            {
                path: '/bifenLiveScores',
                meta: {title: "Tỷ số bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/LiveScores.vue')
            },
            {
                path: '/bifenResult',
                meta: {title: "Kết quả bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Result.vue')
            },
            {
                path: '/bifenAgenda',
                meta: {title: "Lịch thi đấu bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Agenda.vue')
            },
            {
                path: '/bifen/detail',
                meta: {title: "detail", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Detail.vue')
            },
            {
                path: '/bifen/detail/:id(\\d+)',
                meta: {title: "detail", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Detail.vue')
            },
            {
                path: '/bifen/analyze',
                meta: {title: "analyze", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Analyze.vue')
            },
            {
                path: '/bifen/analyze/:id(\\d+)',
                meta: {title: "analyze", activeMenu: "",keepAlive: true},
                component: () => import('../views/Home/Analyze.vue')
            },
        ]
    },
    // 直播单页面
    {
        path: '/bifen/video',
        meta: {title: "video", activeMenu: "",keepAlive: true},
        component: () => import('../views/seoPage/video.vue')
    },
    // 首页比分单页面
    {
        path: '/frontPage',
        meta: {title: "frontPage", activeMenu: "",keepAlive: true},
        component: () => import('../views/seoPage/frontPage.vue'),
        redirect: "/frontLiveScores",
        children: [
            {
                path: '/frontLiveScores',
                meta: {title: "Tỷ số bóng đá", activeMenu: "",keepAlive: true},
                component: () => import('../views/seoPage/LiveScores.vue')
            },
            {
                path: '/fontBasket',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/seoPage/basket.vue')
            },
        ]
    },
    // 篮球单页面
    {
        path: '/bifen/basket',
        component: () => import('../views/seoPage/Layout.vue'),
        meta: {title: "bong_ro", activeMenu: "",keepAlive: true},
        redirect: '/basket',
        children: [
            {
                path: '/bifenBasket',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/basket.vue')
            },
            {
                path: '/bifenBoogAgenda',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/boogAgenda.vue')
            },
            {
                path: '/bifenBoogResult',
                meta: {title: "Tỷ số bóng rổ", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/boogResult.vue')
            },
            {
                path: '/bifen/basket/analysis/',
                meta: {title: "", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/analysis.vue')
            },
            {
                path: '/bifen/basket/skillStatistics',
                meta: {title: "", activeMenu: "",keepAlive: true},
                component: () => import('../views/basketball/skillStatistics.vue')
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

