<template>
  <!-- 头部 -->
  <div id="header-wrapper">
    <!-- 顶部导航栏 -->
<!--    <TopTools></TopTools>-->

    <!-- 头部导航栏 -->
    <AppTop></AppTop>

    <!--    菜单栏-->
<!--    <navMenu></navMenu>-->
  </div>

  <!-- 右下角的三个图标 -->
  <AppReturnTop></AppReturnTop>

  <!-- 主体区域 -->
  <div>
    <!--面包屑-->
    <BreadCrumb></BreadCrumb>
    <keep-alive>

      <router-view> </router-view>

    </keep-alive>
  </div>

  <!-- <div>底部</div> -->
  <AppBottom></AppBottom>

</template>

<script>
import TopTools from '@/components/TopTools.vue'
import AppTop from '@/components/AppTop.vue'
import AppBottom from '@/components/AppBottom.vue'
import AppReturnTop from '@/components/AppReturnTop.vue'
import BreadCrumb from "@/components/BreadCrumb.vue";
import navMenu from "@/components/navMenu.vue";

export default {
  components: {
    BreadCrumb,
    TopTools,
    AppTop,
    AppBottom,
    AppReturnTop,
    navMenu
  }
}


</script>


<style lang="less" scoped>
#header-wrapper {
  background-color: @primaryColor !important;
  padding-top: 10px;
  width: 100%;
}

div {
  display: block;
}
</style>
