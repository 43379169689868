<template>
      <div id="fulltab">
        <div id="newtop">
          <ul id="menu">
            <!--          <li id="ball9"><a href="/predictions" class=""><i></i>Tin Bóng Đá</a></li>-->
            <li><a href="/" @click="ballFunMen('ball1')" :class="topActiveMenu === 'ball1' ? 'tab_on':'' " id="ball1"><i></i>Bóng đá</a></li>
  <!--          <li><a href="/basket" @click="ballFunMen('ball2')" :class="topActiveMenu === 'ball2' ? 'tab_on':'' " id="ball2"><i></i>Bóng rổ</a></li>-->

            <li><a href="/LiveBroadcast" @click="ballFunMen('ball6')" :class="topActiveMenu === 'ball6' ? 'tab_on':'' " id="ball6"><i></i>Trực tiếp</a></li>
            <li><a href="/consult" @click="ballFunMen('ball7')" :class="topActiveMenu === 'ball7' ? 'tab_on':'' " id="ball7"><i></i>Thông tin</a></li>
            <!--          <li id="ball3"><a href="/tennis" class=""><i></i>Tennis</a></li>-->
            <!--          <li id="ball5"><a href="//sports.bongdalu4.com/bong-chay" class=""><i></i>Bóng chày</a></li>-->

          </ul>


  <!--        <el-menu-->
  <!--          :default-active="activeDefault"-->
  <!--          class="el-menu-demo"-->
  <!--          mode="horizontal"-->
  <!--          text-color="#fff"-->
  <!--          active-text-color="#2574dc"-->
  <!--          @select="handleSelect"-->
  <!--        >-->
  <!--          <el-menu-item index="/liveScores" class="menuItem">Tỷ số trực tuyến</el-menu-item>-->
  <!--          <el-menu-item index="/result" class="menuItem">Kết quả</el-menu-item>-->
  <!--          <el-menu-item index="/agenda" class="menuItem">Lịch thi đấu</el-menu-item>-->
  <!--        </el-menu>-->

        </div>


      </div>
</template>



<style scoped lang="less">
#fulltab {
  border-radius: 10px;

  #newtop {

    margin-right: auto;
    margin-left: auto;
    position: relative;


    #menu {
      display: flex;
      padding: 2px;
      //position: absolute;
      //top: -45px;
      //left: 250px;
      z-index: 1;
      margin-left: 10px;


      .tab_on {
        font-weight: bold;
        opacity: 1;
      }

      .tab_on:after {
        display: inline-block;
        position: absolute;
        top: 32px;
        border-radius: 20px;
        left: 35px;
        width: 60%;
        height: 2px;
        content: "";
        background-color: #fff;

      }


      li {
        position: relative;
        float: left;
        margin-right: 8px;
        font-size: 12px;
      }


      li:hover a:after {
        width: calc(100% - 44px);
        background: #2760ab;
        box-shadow: 0 0 20px rgb(255, 255, 255, 0); // 移动到目标元素上面则展示横线
      }

      li a:after {
        content: "";
        position: absolute;
        left: 35px;
        bottom: 0;
        height: 2px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 0 0 0 #fff;
        transition: width .3s cubic-bezier(.22, .61, .36, 1) 0s, left .3s cubic-bezier(.22, .61, .36, 1) 0s, box-shadow 1s cubic-bezier(1, 1.39, 1, -1.67) 0s;
        width: 0;
        overflow: hidden;
      }

      li a {
        transition: all .2s linear;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        color: black;
        line-height: 35px;
        text-align: center;
        margin-right: 5px;
        padding: 0 10px;
        opacity: .75;
        position: relative;

        i {
          display: inline-block;
          width: 17px;
          height: 17px;
          margin-top: 10px;
          margin-right: 7px;
          background-size: 100%;
        }

      }


    }
  }
}

#ball9 i {
  background-image: url("../../public/images/news.png");
}

#ball1 i {
  background-image: url("../../public/images/soccer.png");
}

#ball2 i {
  background-image: url("../../public/images/basketball.png");
}

#ball3 i {
  background-image: url("../../public/images/Tennis.png");
}

#ball5 i {
  background-image: url("../../public/images/Baseball.png");
}

#ball6 i {
  background-image: url("../../public/images/broadcast.png");
}

#ball7 i {
  background-image: url("../../public/images/message.png");
}

</style>