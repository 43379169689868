<template>
    <div id="returnTop" style="position: fixed;z-index:195;right:50px;bottom:50px;">
        <a href="javascript:window.scroll(0,0);" title="Trở về đầu trang" id="backTop" style=""><img src="/images/top.gif"
                alt="Trở về đầu trang" style="margin-bottom:30px;"></a>
        <br>

<!--        <a onclick="_socialMediaWin.open()" title="Chia sẻ"><img src="/images/share.jpg" style="margin-bottom:2px;"-->
<!--                alt="Chia sẻ"><br></a>-->
<!--        <a href="/feedback" title="Phản hồi" target="_blank" id="feedBack" style=""><img src="/images/fb.gif" alt="Phản hồi"-->
<!--                style="height:31px;"></a>-->
</div>
</template>

<script>

</script>