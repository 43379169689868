import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入基础样式
import '@/assets/styles/base.css'

// 引入iconfont
import '@/assets/iconfont/iconfont.css'

// 引入 ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import { Message , error} from '@/utils/ruoyi'

//适配开发中
// import './utils/flexible'
// import './utils/rem'


const app = createApp(App)

//挂载提示
app.config.globalProperties.$Message = Message;
app.config.globalProperties.$error = error;





app.use(store)
.use(router)
.use(ElementPlus)
.mount('#app')




