import res from '@/utils/request'


// 获取用户详细信息
export function getTest() {
    return res({
        url: '/bifen/test',
        method: 'get'
    })
}

// 获取实时比分数据
export function getLiveList() {
    return res({
        url: '/bifen/liveList',
        method: 'get'
    })
}

// 获取结果页面数据
export function getResultList() {
    return res({
        url: '/bifen/resultList',
        method: 'get'
    })
}

// 获取日程页面数据
export function getAgendaList() {
    return res({
        url: '/bifen/agendaList',
        method: 'get'
    })
}

// 通用后端get请求接口
export function commonGetReqest(params) {
    return res({
        url: '/caiji/bifen/commonGetReqest',
        method: 'get',
        params: params
    })
}




