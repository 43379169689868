<template>
  <div class="app-top-nav">

    <div class="w">
      <div id="top-tools">
        <div id="top-tools-c">
          <div class="top-tools-left">
            <!--                        <div class="top-tools-btn"> Bản mobile </div>-->
            <!--                        <div class="top-tools-btn">APP</div>-->
            <!--                        <div :class="chooseTime" id="chooseTimeZone" style="width: 80px" @click="showHideTimeZone()">-->
            <!--                            <span id="selectedTimeZone"> {{ timeZone }}</span>-->
            <!--                            <i></i>-->
            <!--                            <ul id="ddlTimeZone" style="display:none;">-->
            <!--                                <li @click="changeTopTimeZone('')" id="timeZone_auto">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">Automatic</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-11)" id="timeZone_-11">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-11</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-10)" id="timeZone_-10">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-10</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-9)" id="timeZone_-9">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-9</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-8)" id="timeZone_-8">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-8</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-7)" id="timeZone_-7">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-7</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-6)" id="timeZone_-6">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-6</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-5)" id="timeZone_-5">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-5</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-4.5)" id="timeZone_-4.5">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-4.5</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-4)" id="timeZone_-4">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-4</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-3.5)" id="timeZone_-3.5">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-3.5</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-3)" id="timeZone_-3">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-3</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-2)" id="timeZone_-2">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-2</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(-1)" id="timeZone_-1">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间-1</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(0)" id="timeZone_0">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林尼治标准时间 0</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                <li @click="changeTopTimeZone(1)" id="timeZone_1">-->
            <!--                                    <font style="vertical-align: inherit;">-->
            <!--                                        <font style="vertical-align: inherit;">格林威治标准时间+1</font>-->
            <!--                                    </font>-->
            <!--                                </li>-->
            <!--                                &lt;!&ndash; <li @click="changeTopTimeZone(2)" id="timeZone_2"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+2</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(3)" id="timeZone_3"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+3</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(3.5)" id="timeZone_3.5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+3.5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(4)" id="timeZone_4"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+4</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(5)" id="timeZone_5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(5.5)" id="timeZone_5.5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+5.5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(5.75)" id="timeZone_5.75"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+5.75</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(6)" id="timeZone_6"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+6</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(6.5)" id="timeZone_6.5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+6.5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(7)" id="timeZone_7"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+7</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(8)" id="timeZone_8"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+8</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(9)" id="timeZone_9"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+9</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(9.5)" id="timeZone_9.5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+9.5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(10)" id="timeZone_10"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+10</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(10.5)" id="timeZone_10.5"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+10.5</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(11)" id="timeZone_11"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+11</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(12)" id="timeZone_12"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+12</font></font></li>-->
            <!--                                <li @click="changeTopTimeZone(13)" id="timeZone_13"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">格林威治标准时间+13</font></font></li> &ndash;&gt;-->
            <!--                            </ul>-->
            <!--                        </div>-->

            <!--                        <div :class="chooseOdds" id="chooseOddsType" style="width: 95px" @click="showHideOddsType()">-->
            <!--                            <span id="selectedOddsType">-->
            <!--                                <font style="vertical-align: inherit;">-->
            <!--                                    <font style="vertical-align: inherit;">TL Malay</font>-->
            <!--                                </font>-->
            <!--                            </span>-->
            <!--                            <i></i>-->
            <!--                            <ul id="ddlOddsType" style="display:none;">-->
            <!--                                <li @click="changeTopOddsType(4)" id="oddsType_4">TL thập phân</li>-->
            <!--                                <li @click="changeTopOddsType(1)" id="oddsType_1">TL Hong Kong </li>-->
            <!--                                <li @click="changeTopOddsType(2)" id="oddsType_2">TL Indo</li>-->
            <!--                                <li @click="changeTopOddsType(3)" id="oddsType_3">TL Mỹ</li>-->
            <!--                                <li @click="changeTopOddsType(5)" id="oddsType_5">TL Malay</li>-->

            <!--                            </ul>-->
            <!--                        </div>-->


<!--            <div class="theme-div">-->
<!--              <label for="DarkModeCheck" class="theme">-->
<!--                <input type="checkbox" name="DarkModeCheck" id="DarkModeCheck" @click="swithcDark();"><span-->
<!--                class="theme-style"></span>-->
<!--              </label>-->
<!--            </div>-->


          </div>

          <div class="top-tools-left2" style="height:36px;margin:0">
            <!-- <p class="text" v-for="(text, index) in arr" :key="index">{{ text }}</p> -->
            <p class="text"></p>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";

const list = ref([]);
const timeZone = ref("Automatic")
const chooseTime = ref("Choose-tool")
const chooseOdds = ref("Choose-tool")
const darkCheck = ref(true)
const arr = ref([
  'Đánh dấu tên miền dự bị để tránh lạc đường: bongdalu6.com',
  'Quảng cáo chi phí thấp hiệu quả cao tại bongdalu4.com, vui lòng liên lạc qua Email abongdalu@gmail.com',
  'Đánh dấu tên miền dự bị để tránh lạc đường: bongdalu6.com',
  'Quảng cáo chi phí thấp hiệu quả cao tại bongdalu4.com, vui lòng liên lạc qua Email abongdalu@gmail.com',
])


//显示隐藏时区下拉
function showHideTimeZone(o) {
  var timeZone = document.getElementById('ddlTimeZone');
  var isHide = timeZone.style.display == "none";
  if (isHide) {
    document.getElementById('chooseOddsType').className = "Choose-tool";
    document.getElementById('ddlOddsType').style.display = "none";
    chooseTime.value = "Choose-tool on";
    timeZone.style.display = "";
  } else {
    chooseTime.value = "Choose-tool";
    timeZone.style.display = "none";
  }
}

//显示隐藏赔率下拉
function showHideOddsType() {
  var oddsType = document.getElementById('ddlOddsType');
  var isHide = oddsType.style.display == "none";
  if (isHide) {
    document.getElementById('chooseTimeZone').className = "Choose-tool";
    document.getElementById('ddlTimeZone').style.display = "none";
    chooseOdds.value = "Choose-tool on";
    oddsType.style.display = "";
  } else {
    chooseOdds.value = "Choose-tool";
    oddsType.style.display = "none";
  }
}

// 切换黑夜与白天
function swithcDark() {
  var darkModeCheck = document.getElementById('DarkModeCheck');
  if (darkCheck.value) {
    darkModeCheck.style.backgroundColor = "@darkColor";
  } else {
    darkModeCheck.style.backgroundColor = "@darkColor";
  }
}

</script>

<style lang="less" scoped>
.app-top-nav {
  //background-color: #212121;
  line-height: 36px;
  height: 36px;
  margin: 0 auto;
  color: #fff;
}

.top-tools-left {
  float: left;
}

.top-tools-btn {
  display: block;
  padding: 0 10px;
  border: 1px solid rgba(255, 255, 255, .5);
  float: left;
  margin: 7px 10px 0 0;
  line-height: 20px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.Choose-tool {
  float: left;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  cursor: pointer;

  i {
    display: inline-block;
    content: "";
    top: 2px;
    transition: 0.2s;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #FFF;
    margin-left: 4px;
  }

  ul {
    width: 320px;
    padding: 10px;
    background-color: #212121;
    position: absolute;
    left: 0;
    top: 44px;
    z-index: 9999;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .6);
    animation: popover .2s ease-in-out;
    border-radius: 6px;
    white-space: nowrap;

    li {
      height: 26px;
      line-height: 26px;
      padding: 0 5px;
    }
  }
}

.Choose-tool.on i {
  transform: rotate(180deg);
}

.theme {
  cursor: pointer;

  .theme-style {
    height: 24px;
    width: 48px;
    background-color: #444;
    border-radius: 20px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    float: right;
    vertical-align: middle;
    background-image: url("../../public/images/theme.png");
    background-repeat: no-repeat;
  }
}

.theme-div {
  margin-top: 6px;
  float: left;

  input {
    display: none;
  }
}

.theme > input[type="checkbox"]:checked + .theme-style {
  background-color: #000;
}

.theme .theme-style:before {
  display: block;
  content: '';
  height: 22px;
  position: absolute;
  width: 22px;
  background-color: #fff;
  border-radius: 20px;
  left: 0.1em;
  top: 0.1em;
  background-image: url("../../public/images/light.png");
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

// 动态样式，根据checkbox 的值来转换样式
.theme > input[type="checkbox"]:checked + .theme-style:before {
  left: 50%;
  background-image: url("../../public/images/dark.png");
  background-repeat: no-repeat;
  background-color: #fff;
}

.top-tools-left2 {
  float: left;
  padding-left: 20px;
  width: 570px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #ffde00;

}

.top-tools-left2 a:hover {
  color: #ffde00;
  text-decoration: none;
  cursor: pointer;
}

.top-tools-left2 {
  // animation: myMove 5s linear infinite;
  animation-fill-mode: forwards;
  color: #ffde00;
}

/*文字无缝滚动*/
@keyframes myMove {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-150px);
  }
}
</style>


