<template>

  <div class="menu">
            <el-menu
              :default-active="activeDefault"
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#1E1F22"
              @select="handleSelect"
            >
              <el-menu-item index="/liveScores" class="menuItem">Tỷ số trực tuyến</el-menu-item>
              <el-menu-item index="/liveScores/result" class="menuItem">Kết quả</el-menu-item>
              <el-menu-item index="/liveScores/agenda" class="menuItem">Lịch thi đấu</el-menu-item>
            </el-menu>
<!--    <div style="margin: 10px 0">-->
<!--      <el-radio-group v-model="radio2" class="el_radio_div">-->
<!--        <el-radio-button label="New York" />-->
<!--        <el-radio-button label="Washington" />-->
<!--        <el-radio-button label="Los Angeles" />-->
<!--      </el-radio-group>-->
<!--    </div>-->
  </div>
</template>

<script setup>

import {computed, ref} from "vue";
import store from "@/store";
import {useRouter} from "vue-router";

const router = useRouter();
let activeDefault;
activeDefault = computed(() => {
  return router.currentRoute.value.fullPath
})
const radio2 = ref('New York')

function handleSelect(key, keyPath) {
  // 重置数据
  store.state.liveScores = []
  store.state.resultScores = []
  store.state.agendaScores = []

  const ro = '/' + key
  router.push(ro.replace('//', '/'))

}

</script>

<style lang="less" scoped>

.menu {
  .el_radio_div{
    .el-radio-button{
      margin-right: 10px !important;
      border: 1px slategrey solid;
      background-color: #2760AB;

    }
  }

}

.el-menu-demo {
  display: flex;
  border-radius: 4px 4px 0 0;
  //justify-content: center;
  margin: 10px 0;
  //border-bottom: solid 1px var(--el-menu-border-color);
}

.el-menu--horizontal.el-menu {
  //border-bottom: solid 0px var(--el-menu-border-color);
}

.menuItem {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  line-height: 35px;
  height: 28px;
  font-weight: bold;
  color: #222222;
  background-color: #E5E5E5;
  text-align: center;

  cursor: pointer;
  width: 12%;
  font-size: 12px;
}
.el-menu--horizontal > .el-menu-item {
  border-radius: 4px 4px 0 0;
  margin-right: 6px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0;
  color: @primaryColor;
  background-color: @primaryColor;;
}

</style>