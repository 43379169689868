<template>
  <div class="w">
    <div class="crumbs">
      <el-breadcrumb class="app-breadcrumb" :separator-icon="ArrowRight">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
<!--            数据仓库做了之后再做这里的二级菜单跳转-->
<!--          <span v-if="item.redirect === 'noRedirect' || index === levelList.length - 1"-->
<!--                class="no-redirect">{{ item.meta.title }}</span>-->
<!--            <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>-->

            <span class="no-redirect" @click.prevent="handleLink(item)">{{ item.meta.title }}</span>
<!--            <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>-->
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {ArrowRight} from '@element-plus/icons-vue'
import {computed, ref, watchEffect} from "vue";

const route = useRoute()
const router = useRouter()
const levelList = ref([])
// 当前路由的匹配记录

watchEffect(() => {
  const cRoute = router.currentRoute
  getBreadcrumb()
})


function getBreadcrumb() {
  let handleBT = ''
  const breadTitle = sessionStorage.getItem('breadTitle');
  if (breadTitle !== undefined) {
    handleBT = JSON.parse(breadTitle)
  }
  let matched = deepCopy(route.matched);
  for (let i = 0; i < matched.length; i++) {
    const v = matched[i]
    if (v.meta.title === 'detail') {
      try {
        v.meta.title = handleBT.teamData
      } catch (e) {
        console.log("需要处理")
        return
      }
      const raceData = handleBT.raceData
      let obj = {
        meta: {title: raceData}
      }
      matched.splice(i, 0, obj);
      //sessionStorage.removeItem('breadTitle');
    }
  }

  levelList.value = matched
}

// 深拷贝数组
function deepCopy(arr) {
  if (Array.isArray(arr)) {
    return arr.map(deepCopy);
  } else if (typeof arr === 'object' && arr !== null) {
    const newObj = {};
    for (const key in arr) {
      if (arr.hasOwnProperty(key)) {
        newObj[key] = deepCopy(arr[key]);
      }
    }
    return newObj;
  } else {
    return arr;
  }
}

//点击面板屑事件
function handleLink(item) {
  const {redirect, path} = item
  if (redirect) {
    router.push(redirect)
    return
  }
  if (path) router.push(path)

}


</script>


<style scoped>

.crumbs {
  margin: 10px 0 10px 0px;
}

.el-breadcrumb {
  font-size: 12px;
  line-height: 1;
}

.no-redirect{
  cursor: pointer;
}

.no-redirect:hover{

  color: #0d6efd;

}
</style>
