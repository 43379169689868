<template>
  <div id="bottom">
    <div class="network">
      <a href="https://www.tiktok.com/@369bongda.com" target="_blank" rel="nofollow">
        <img src="/images/share/Tiktok.png" alt="Tiktok">Tiktok
      </a>
      <a href="https://www.pinterest.com" target="_blank" rel="nofollow">
        <img src="/images/share/Pinterest.png" alt="Pinterest">Pinterest
      </a>
      <a href="https://twitter.com" target="_blank" rel="nofollow">
        <img src="/images/share/Twitter.png" alt="Twitter">Twitter
      </a>
      <a href="https://www.facebook.com" target="_blank" rel="nofollow">
        <img src="/images/share/Facebook.png" alt="Facebook">Facebook
      </a>
    </div>
    <footer>
      <div class="webid"><a href="/" target="_blank">369bongda.com</a></div>
      <div class="webid"><a target="_blank">|</a></div>
      <div class="webid"><a target="_blank">liên hệ chúng tôi：365bongdatv@gmail.com</a></div>
      <div class="webid"><a target="_blank">|</a></div>
      <div class="webid"><a target="_blank" href="/sitemap.xml">Sơ đồ trang web</a></div>
      <div class="webid"><a target="_blank">|</a></div>
      <div class="webid"><a target="_blank">Đã đăng ký Bản quyền：©2023 365bongda</a></div>
    </footer>
    <!--        <ul class="f_menu">-->
    <!--            <li><a href="/aboutus" target="_blank" rel="nofollow">Về chúng tôi</a></li>-->
    <!--            <li><a href="/disclaimer" target="_blank" rel="nofollow">Tuyên bố miễn trách</a></li>-->
    <!--            <li><a href="/sitemap" target="_blank">Sơ đồ trang web</a></li>-->
    <!--            <li><a href="/feedback" target="_blank" rel="nofollow">Phản hồi</a></li>-->
    <!--            <li><a href="/link" target="_blank" rel="nofollow">Trao đổi link</a></li>-->
    <!--            <li><a href="https://www.isportsapi.com/?from=bongdalu" target="_blank">API</a></li>-->
    <!--            <li><a href="/freemap" target="_blank">Livescore miễn phí </a></li>-->
    <!--            <li><a href="/adcontact" target="_blank" rel="nofollow">Quảng cáo</a></li>-->
    <!--        </ul>-->
    <!--        <div id="p_link" runat="server" class="f_menu">-->
    <!--            <b>Liên Kết</b> &nbsp;<font>-->

    <!--                <a href="https://www.goaloo18.com/" target="_blank" title="Goaloo">Goaloo</a>-->
    <!--                <span>&nbsp;|&nbsp;</span>-->
    <!--                <a href="https://www.scoreman2.com/" target="_blank" title="Scoreman">Scoreman</a>-->
    <!--                <span>&nbsp;|&nbsp;</span>-->
    <!--                <a href="https://betimate.com/vi" target="_blank" title="Soi kèo bóng đá Betimate">Soi kèo bóng đá-->
    <!--                    Betimate</a>-->
    <!--            </font>-->
    <!--        </div>-->
    <!--        <div style="line-height: 30px">Copyright © 2023 Powered By <a href="//www.369bongda.com/" title="Bongdalu" target="_blank">Bongdalu</a> All Rights Reserved.</div>-->
    <!--        <div class="explain">-->
    <!--          369bongda.com là một website chuyên cập nhật tỷ số trực tuyến bóng đá nhanh và chính xác nhất tại Việt Nam. Ở-->
    <!--            đây, bạn có thể theo dõi tất cả các thông tin như tỷ số trực tuyến, TL trực tuyến, kết quả, lịch thi đấu, bảng-->
    <!--            xếp hạng, kho dữ liệu hay nhận định của các giải đấu bóng đá/bóng rổ/tennis/bóng bầu dục.<br>-->
    <!--            Nói chung, 369bongda.com có thể thỏa mãn tất cả các nhu cầu của bạn, chắc là một lựa chọn tốt nhất đối với bạn.-->
    <!--            <br>-->
    <!--            Chú ý: Tất cả quảng cáo trên web chúng tôi, đều là ý kiến cá nhân của khách hàng, không có liên quan gì-->
    <!--            với web chúng tôi. Tất cả thông tin mà web chúng tôi cung cấp chỉ mang tính chất tham khảo, xin quý khách chú ý-->
    <!--            tuân thủ pháp luật của nước sở tại, không được phép sử dụng thông tin của chúng tôi để tiến hành hoạt động phi-->
    <!--            pháp. Nếu không, quý khách sẽ phải hoàn toàn chịu trách nhiệm trước pháp luật. Web chúng tôi sẽ không chịu bất-->
    <!--            kì trách nhiệm nào.-->
    <!--        </div>-->


  </div>
</template>

<script>

</script>

<style lang="less" scoped>
#bottom {
  clear: both;
  text-align: center;
  padding: 15px 0;
  background-color: #555;
  color: #999;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  a{
    color: #FFFFFF;
  }
  footer{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    .webid {
      text-align: center;
      margin:0 5px;
      a {
        display: inline-block;
        padding: 3px 15px;
        font-size: 14px;
        color: #fff;
        font-family: Gotham, Helvetica Neue, Helvetica, Arial, " sans-serif";
      }
      a:hover{
        //color: @primaryColor;
      }
    }
  }



  .network {
    a {
      display: inline-block;
      padding: 5px 8px;
      margin-right: 10px;
    }

    img {
      vertical-align: -6px;
      margin-right: 6px;
      width: 23px;
      height: 23px;
    }
    a:hover{
      padding: 8px 20px;
      background-color: #7a7979;
      border-radius: 2px;
    }
  }

  .f_menu {
    width: 1000px;
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;

    li {
      display: inline;
      padding: 0 10px;
      line-height: 20px;

    }
    a:hover{
      padding:0px 10px;
      background-color: #7a7979;
      border-radius: 2px;
    }
  }

  .explain {
    width: 1000px;
    text-align: justify;
    line-height: 20px;
    margin: 5px auto;
  }
  font-size: 12px;

}

</style>