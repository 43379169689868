<template>
<!--    <button @click="sendDataToServer">给后台发送消息</button>-->
</template>

<script>
import store from "@/store";
import snow from "@/utils/snow.js";
import {useRoute} from "vue-router";
const route = useRoute();
export default {
  name: "WebSocket",
  data() {
    return {
      // ws是否启动
      wsIsRun: false,
      // 定义ws对象
      webSocket: null,
      // ws请求链接（类似于ws后台地址）
      ws: '',
      // ws定时器
      wsTimer: null,
      // 指定倒计时
      time : 3600, //秒数
      intervalId : null,
      waitTime : 3600,
    }
  },
  computed: {
    groupMsgMap() {
      return store.state.groupMsgMap;
    }
  },
  async mounted() {
    this.wsIsRun = true
    this.wsInit()
    this.initCountdown();
  },
  watch: {
    groupMsgMap(newValue, oldValue) {
      this.sendGroupMessageToServer(newValue);
    }
  },
  methods: {
    sendGroupMessageToServer(newValue) {
      if (this.webSocket.readyState === 1) {
        this.webSocket.send(JSON.stringify(newValue))
      } else {
        throw Error('服务未连接')
      }
    },
    sendDataToServer() {
      const obj = {
        type : "checkPullFlag",
        path : window.location.href
      }
      if (this.webSocket.readyState === 1) {
        // this.webSocket.send('来自前端的数据')
        this.webSocket.send(JSON.stringify(obj))
      } else {
        throw Error('服务未连接')
      }
    },
    /**
     * 初始化ws
     */
    wsInit() {
      const snowflake = new snow(1n, 1n, 0n);
      const uniqueId = snowflake.nextId();
      if (store.state.liveusername === undefined) {
        store.state.liveusername = uniqueId + ""
      }
      // http协议下位 ws， https则需要升级为 wss
      // const wsuri = 'ws://369bongda.com/websocket/' + store.state.liveusername
      const wsuri = 'wss://369bongda.com/websocket/' + store.state.liveusername
      // const wsuri = 'ws://localhost:8080/websocket/' + store.state.liveusername
      this.ws = wsuri
      if (!this.wsIsRun) return
      // 销毁ws
      this.wsDestroy()
      // 初始化ws
      this.webSocket = new WebSocket(this.ws)
      // ws连接建立时触发
      this.webSocket.addEventListener('open', this.wsOpenHanler)
      // ws服务端给客户端推送消息
      this.webSocket.addEventListener('message', this.wsMessageHanler)
      // ws通信发生错误时触发
      this.webSocket.addEventListener('error', this.wsErrorHanler)
      // ws关闭时触发
      this.webSocket.addEventListener('close', this.wsCloseHanler)

      // 检查ws连接状态,readyState值为0表示尚未连接，1表示建立连接，2正在关闭连接，3已经关闭或无法打开
      clearInterval(this.wsTimer)
      this.wsTimer = setInterval(() => {
        if (this.webSocket.readyState === 1) {
          clearInterval(this.wsTimer)
        } else {
          console.log('ws建立连接失败')
          this.wsInit()
        }
      }, 3000)
    },
    wsOpenHanler(event) {
      console.log('ws建立连接成功')
    },
    logGroupMsg(e) {
      try {

        store.state.groupMes = JSON.parse(e.data)
      } catch (e) {
      }
    },
    wsMessageHanler(e) {
      console.log('wsMessageHanler' + e)
      this.sendDataToServer()
      this.logGroupMsg(e);
      try {
        const data = JSON.parse(e.data).data
        if (data.type === 'liveScores') {
          // console.log("信息推送中")
          store.state.liveScores = e.data
        } else if (data.type === 'result') {
          store.state.resultScores = e.data
        } else if (data.type === 'agenda') {
          store.state.agendaScores = e.data
        } else if (data.hiddenNum !== undefined){
          store.state.baskLiveData = data
        }

      } catch (e) {

      }
    },
    /**
     * ws通信发生错误
     */
    wsErrorHanler(event) {
      console.log(event, '通信发生错误')
      this.wsInit()
    },
    /**
     * ws关闭
     */
    wsCloseHanler(event) {
      console.log(event, 'ws关闭')
      // this.wsInit()
    },
    /**
     * 销毁ws
     */
    wsDestroy() {
      if (this.webSocket !== null) {
        this.webSocket.removeEventListener('open', this.wsOpenHanler)
        this.webSocket.removeEventListener('message', this.wsMessageHanler)
        this.webSocket.removeEventListener('error', this.wsErrorHanler)
        this.webSocket.removeEventListener('close', this.wsCloseHanler)
        this.webSocket.close()
        this.webSocket = null
        clearInterval(this.wsTimer)
      }
    },

    initCountdown() {
      this.intervalId = setInterval(this.handleCountdown, 1000);
      const body = document.querySelector('html');
      body.addEventListener("click", this.resetCountdown);
      body.addEventListener("keydown", this.resetCountdown);
      body.addEventListener("mousemove", this.resetCountdown);
      body.addEventListener("mousewheel", this.resetCountdown);
    },

    handleCountdown() {
      this.waitTime--;
      // 倒计时结束
      // console.log(this.waitTime)
      if (this.waitTime <= 0) {
        this.doInspection();
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }
    },

    resetCountdown() {
      // TODO 重置倒计时之前先把之前的状态关闭
      // exp: 比如关闭动画等
      this.waitTime = this.time;
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.handleCountdown, 1000);
    },

    doInspection() {
      // TODO 计时结束后的操作
      console.log('doInspection');
      this.wsDestroy();
    }

  }
}
</script>

<style scoped>

</style>
