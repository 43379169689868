<template>
  <div id="top" class="w">
    <!--    <div id="topAdDiv">-->
    <!--        <a href="/ad/201.html" target="_blank" title="" rel="nofollow"><img-->
    <!--                src="https://img_bongdalu.thscore.fun/mn/20230821165042.gif" width="350" height="45"></a>-->
    <!--        <a href="/ad/900.html" target="_blank" title="" rel="nofollow"><img-->
    <!--                src="https://img_bongdalu.thscore.fun/mn/20230220082311.gif" width="350" height="45"></a>-->
    <!--        <a href="/ad/901.html" target="_blank" title="" rel="nofollow"><img-->
    <!--                src="https://img_bongdalu.thscore.fun/mn/20230608150807.gif"></a>-->
    <!--    </div>-->

    <div class="top">
      <a id="logoSpan" href="/" style="cursor: pointer;">
        <!--                <img alt="邦达鲁标志" class="weblogo" :src="imgs.logoSrc">-->
        <img alt="邦达鲁标志" class="weblogo" src="/images/logo6.png">
        <img alt="邦达鲁标志" class="weblogo" src="/images/logo5.png">
      </a>
      <!--      <div class="loginBox">-->
      <!--        <div id="login_icon" v-if="!UserInfo">-->
      <!--          <a title="Đăng nhập"   target="_self" class="wd-white" @click="getTest()" rel="nofollow">-->
      <!--            <span class="sign_in_btn">Đăng nhập</span>-->
      <!--          </a>-->
      <!--        </div>-->
      <!--        <div id="login_icon" v-if="UserInfo" >-->
      <!--          <a class="name">-->
      <!--            <img src="../public/images/user.png" style="width: 25px;height: 25px">-->
      <!--            <span class="sign_in_btn">{{UserInfo.nickName}}</span>-->
      <!--          </a>-->
      <!--        </div>-->

      <!--        <el-popconfirm title="Xác nhận Rút lui ?" @confirm="quit()">-->
      <!--          <template #reference>-->
      <!--            <b class="b" v-if="UserInfo" style="cursor: pointer;margin: 3px 0">-->
      <!--              <img src="../public/images/quit.png" style="width: 25px;height: 25px">-->
      <!--            </b>-->
      <!--          </template>-->
      <!--        </el-popconfirm>-->
      <!--      </div>-->
    </div>

    <div id="fulltab">
      <div id="newtop">
        <ul id="menu">
          <li :class="activeDefault === 'liveScores' ? 'tab_Activate': '' ">
            <a :href="'/liveScores' " :class="activeDefault === 'liveScores'  ? 'tab_on':'' " id="ball1"><i></i>Bóng đá</a>
          </li>
          <li :class="activeDefault === 'basket' ? 'tab_Activate': '' ">
            <a href="/basket"  :class="activeDefault === 'basket'  ? 'tab_on':'' " id="ball2"><i></i>Bóng rổ</a>
          </li>
          <li :class="activeDefault === 'LiveBroadcast'  ? 'tab_Activate': '' ">
            <a :href="'/LiveBroadcast' " :class="activeDefault === 'LiveBroadcast'  ? 'tab_on':'' " id="ball6"><i></i>phát trực tiếp </a>
          </li>
          <li :class="activeDefault === 'consult'  ? 'tab_Activate': '' ">
            <a :href="'/consult' " :class="activeDefault === 'consult'  ? 'tab_on': '' " id="ball7"><i></i>Tin mới</a>
          </li>
        </ul>


      </div>


    </div>

<!--    <login ref="logins" @DownValueChange="emitLogin"></login>-->


  </div>
</template>

<script setup>
import {ref, reactive, computed, watch, watchEffect, nextTick, onBeforeMount} from 'vue';
// import logoSrc from '/images/Logo.png'
import {useRoute, useRouter} from "vue-router";
import store from "@/store";
const route = useRoute();
const router = useRouter();
// 默认激活的菜单
let activeDefault= ref('');
// let imgs = reactive({logoSrc})
let UserInfo = ref({})
const logins = ref(null)

function handleSelect(key, keyPath) {
  // 重置数据
  store.state.liveScores = []
  store.state.resultScores = []
  store.state.agendaScores = []
  const ro = '/' + key
  router.push(ro.replace('//', '/'))
}

// 监听器
watchEffect(() => {
  const path = route.path
  activeDefault.value = path.split('/')[1]
})

const getTest =()=>{
  logins.value.setLoginDialog(true);
}

const quit=()=>{
  UserInfo.value = {}
  sessionStorage.removeItem('UserInfo')
  window.location.reload()
}

const emitLogin =()=>{
  try {
    UserInfo.value = JSON.parse(sessionStorage.getItem('UserInfo'))
    if (UserInfo.value.isWsOk === undefined) {
      return
    }
  } catch (e) {
  }

}

</script>

<style lang="less" scoped>
#top .top {
  margin: 0 auto;
  height: auto;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1040px;
  #logoSpan {
    display: inline-block;
    left: 0;
    top: 10px;
  }
  .weblogo {
    height: 40px;
  }
}

img {
  border-width: 0;
}
#fulltab {
  border-radius: 10px;
  #newtop {
    margin-right: auto;
    margin-left: auto;
    #menu {
      display: flex;
      z-index: 1;
      .tab_on {
        font-weight: bold;
        color: #0b79c9;
      }
      .tab_on:after {
        display: inline-block;
        border-radius: 20px;
        width: 60%;
        background-color: #fff;
      }
      .tab_Activate{
        background-color: white;
        color: #2760AB !important;
        border-radius: 10px 10px 0 0;
      }
      .tab_Activate{

      }


      li {
        position: relative;
        float: left;
        margin-right: 8px;
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        a{
          color: #FFFFFF;
        }
      }


      li:hover a:after {
        //width: calc(100% - 44px);
        //background: #fff;
        //box-shadow: 0 0 20px rgb(255, 255, 255, 0); // 移动到目标元素上面则展示横线
      }

      li a:after {
        content: "";
        position: absolute;
        left: 35px;
        bottom: 0;
        height: 2px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 0 0 0 #fff;
        //transition: width .3s cubic-bezier(.22, .61, .36, 1) 0s, left .3s cubic-bezier(.22, .61, .36, 1) 0s, box-shadow 1s cubic-bezier(1, 1.39, 1, -1.67) 0s;
        width: 0;
        overflow: hidden;
      }

      li a {
        transition: all .2s linear;
        display: flex;
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        margin-right: 5px;
        padding: 0 10px;
        i {
          display: inline-block;
          width: 19px;
          height: 19px;
          margin-top: 10px;
          margin-right: 7px;
          background-size: 100%;
        }

      }


    }
  }
}

#ball9 i {
  background-image: url("../../public/images/news.png");
}

#ball1 i {
  background-image: url("../../public/images/soccer.png");
}

#ball2 i {
  background-image: url("../../public/images/basketball.png");
}

#ball3 i {
  background-image: url("../../public/images/Tennis.png");
}

#ball5 i {
  background-image: url("../../public/images/Baseball.png");
}

#ball6 i {
  background-image: url("../../public/images/broadcast.png");
}

#ball7 i {
  background-image: url("../../public/images/message.png");
}


.menu2 {
  display: flex;
  background-color: @secondColor;
  clear: both;
  height: 35px;
  line-height: 35px;
  border-top: 0;
  overflow: hidden;
  border-radius: 3px 3px 0 0;

  li {
    flex: 1;
    position: relative;
    float: left;
    width: 12.4%;

    a {
      border-right: 1px solid @black20;
      display: inline-block;
      width: 100% !important;
      line-height: 35px;
      height: 35px;
      font-weight: bold;
      text-align: center;
      position: relative;
      cursor: pointer;
    }

    .icon {
      font-size: 16px;
      margin-right: 4px;
      font-weight: normal;
      vertical-align: -1px;
    }

    .on:hover {
      background-color: #e5e5e5;
    }

    .on:hover {
      color: @textPrimary;
    }
  }
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// 登录的样式
.loginBox {
  display: flex;
  align-items: center;
  .name{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    padding: 0 3px;
  }
  a{
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  #login_icon{
    margin-right: 4px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    border: 2px solid #fff;
    cursor: pointer;
    a{
      color: white;
    }
  }
  .sign_in_btn {
    //position: absolute;
    //right: 0;

  }
}

.el-menu-demo {
  background-color: #2760AB;
  border-radius: 4px 4px 0 0;
  text-align: center;
}

.el-menu--horizontal.el-menu {
  border-bottom: solid 0px var(--el-menu-border-color);
}

.el-menu-item:hover {
  background-color: rgb(24, 64, 98) !important;
}

#newtop li {
  //border-radius: 4px 4px 0 0;
}

:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border-bottom: 0px;
  color: #2574dc !important;
  background-color: #fff;
  height: 37px;
}

</style>
