import {createStore} from 'vuex'
import createPersistedstate from "vuex-persistedstate";
export default createStore({
    state: {
        topActiveMenu: 'ball1',
        Bread:[],
        analyID:{
            "_sclassID" : '',
            "_homeTeamID" : '',
            "_guestTeamID" : '',
        }
    },
    getters: {
        topActiveMenu(state) {
            return state.topActiveMenu
        },
        getBread(state){
            return state.Bread
        }
    },
    mutations: {
        SET_TOPACTIVEMENU:(state, topActiveMenu)=>{
            state.topActiveMenu = topActiveMenu
        },
        SET_BREAD:(state, Bread)=>{
            state.Bread = Bread
        },
        SET_ANALYID:(state, analyID)=>{
            state.analyID = analyID
        }
    },
    actions: {},
    modules: {},
    plugins:[
        //默认是存储在localStorage中
        createPersistedstate({
            // 默认存储在localStorage 现改为sessionStorage
            storage: window.sessionStorage,
            // 本地存储数据的键名
            key: "MY_VUEX",
        }),
    ]
})
